import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  message,
  Select,
  Upload,
  notification,
  Spin,
  Typography,
} from 'antd';
import { SafetyCertificateOutlined, UploadOutlined } from '@ant-design/icons';
import DefaultLayout from '../../components/layout/DefaultLayout';
import Header from '../../components/header';
import roleService from '../../services/role';
import scrfService from '../../services/scrf';
import qaService from '../../services/qualityAssurance';
import UserComponent from './../../components/user/index';
import FormListComponent from './../../components/formList/index';
import TaskComponent from '../../components/task';
import { useSelector } from 'react-redux';
import env from '../../env';

export default function QualityAssuranceForm() {

  const { currentUser } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [edit, setEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [formId, setFormId] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isDraft, setIsDraft] = useState(null);
  const location = useLocation();
  const [uatScenarios, setUatScenarios] = useState([]);
  const [key, setKey] = useState(null);
  const [users, setUsers] = useState([]);

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((prevFileList) => {
        return prevFileList ? [...prevFileList, file] : [file];
      });
      return false;
    },
    fileList,
  };
  // const props = {
  //   name: 'file',
  //   action: `${env.API_URL}/attachments`,
  //   method: 'post',
  //   multiple: true,
  //   accept: '.jpg,.jpeg,.png,.gif',
  //   showUploadList: true,
  //   listType: "picture",
  //   className: "upload-list-inline",
  //   onPreview: onPreview,
  //   headers: {
  //     authorization: `Bearer ${currentUser.token}`,
  //     'Content-Type': 'multipart/form-data',
  //   },
  //   data: {
  //     form_id: formId,
  //     key: key,
  //   },
  //   onChange(info) {
  //     const { status } = info.file;

  //     if (status === 'uploading') {
  //     } else if (status === 'done') {
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //       getBase64(info.file.originFileObj, (url) => {
  //       });
  //     } else if (status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   onPreview: onPreview,
  // };

  // console.log(props)

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const keyParam = parseInt(urlParams.get('key'), 10);
    const formIdParam = parseInt(urlParams.get('form_id'), 10);
    const userIds = [];
    urlParams.forEach((value, name) => {
      if (name.startsWith('user_ids[')) {
        userIds.push(parseInt(value, 10));
      }
    });

    // Set the states
    setKey(keyParam);
    setFormId(formIdParam);
    setUsers(userIds);
  }, [location.search]);

  const id = parseInt(new URLSearchParams(location.search).get('id'), 10);

  useEffect(() => {
    if (id) {
      getQaDetails(id);
    }
  }, [id]);

  const getScrfDetails = async (id) => {
    setLoading(true);
    console.log('id ', id);
    console.log('test  ', scrfService.getScrfDetails(id));

    await scrfService.getScrfDetails(id).then((response) => {
      console.log('response ', response);
      setUatScenarios(response.uatScenarios);
      setData(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (key) {
      getScrfDetails(key);
    }
  }, [key]);

  useEffect(() => {
    form.setFieldsValue({
      form_id: formId,
      key: key,
      // assigned_to_ids: users,
    });
  }, [formId, key]);
  const handleSuccess = (message, description) => {
    console.log('message, description');
    setLoading(false);
    setUploading(false);

    setIsModalVisible(false);
    setEdit(null);
    form.resetFields();
    setFileList([]);

    notification.success({
      message,
      description,
    });

    navigate('/dashboard');
    setLoading(false);
    setUploading(false);

    setIsModalVisible(false);
  };
  const getQaDetails = async () => {
    setLoading(true);
    await qaService.getQaDetails(id).then((response) => {
      // console.log(response);
      setData(response);
      setLoading(false);
      setEdit(response);
      form.setFieldsValue({
        // request_title: response.form_name,
        form_id: response.form_id,
        key: response.key,
        assigned_to_ids: response.assigned_to_users.map((user) => user.id),
        // location_id: response.location.id,
      });
      setFileList(
        response.attachments.map((attachment, index) => ({
          uid: index,
          name: attachment.original_title,
          status: 'done',
          url: attachment.filename,
        }))
      );
    });
  };

  const onFinish = async (values) => {

    try {
      const formData = new FormData();
      for (const key in values) {
        if (Array.isArray(values[key])) {
          values[key].forEach((item, index) => {
            formData.append(`${key}[${index}]`, item || '');
          });
        } else {
          formData.append(key, values[key] || '');
        }
        
      // console.log('test ',formData);
        // if (key === 'uat_scenarios') {
        //   values[key].forEach((uatScenario, index) => {
        //     formData.append(`uat_scenarios[${index}][detail]`, uatScenario.detail || '');
        //     formData.append(`uat_scenarios[${index}][status]`, uatScenario.status || '');
        //   });
        // }
      }

      if (fileList) {
        fileList.forEach((file, index) => {
          if (!file.url) { // Check if the file is new
            formData.append(`attachments[${index}]`, file);
          }
        });
      }
      formData.append(`save_as_draft`, isDraft);
      // console.log(formData);

      let response;
      if (edit && edit.id) {
        response = await qaService.updateQAStatus(edit.id, formData);
      } else {
        response = await qaService.postQA(formData);
      }

      if (response.success) {
        handleSuccess('Quality Assurance Request Form Added', response.message);
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: 'Error',
        description: error.response.data.message,
      });
    }
  };
    

  return (
    <DefaultLayout>
      <Spin spinning={loading}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Header
              icon={<SafetyCertificateOutlined />}
              title="Quality Assurance"
            />
          </Col>
        </Row>
        <Card style={{ height: '100vh' }}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="mt-20"
          >
            <Form.Item
              name="form_id"
              label="Form"
              rules={[{ required: true, message: 'Please select Form' }]}
            >
              <FormListComponent
                disabled
                selectedFormId={formId}
                onChange={(value) => {
                  form.resetFields(['key', 'team_ids']);
                  setFormId(value);
                }}
              />
            </Form.Item>

            {formId ? (
              <Form.Item
                name="key"
                label="Select Task"
                rules={[{ required: true, message: 'Please select Task' }]}
              >
                <TaskComponent id={formId} selectedKey={key} disabled />
              </Form.Item>
            ) : null}

            {/* <Form.Item
              name="assigned_to_ids"
              label="Users"
            >
              <UserComponent mode="multiple" selectedUsers={users} disabled />
            </Form.Item> */}

            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: 'Please select Status' }]}
            >
              <Select size="large" placeholder="Select Status">
                <Select.Option value="Testing Ok">Testing Ok</Select.Option>
                <Select.Option value="Modification Required">Modification Required</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="feedback"
              label="Feedback"
              rules={[{ required: true, message: 'Please provide feedback' }]}
            >
              <Input.TextArea rows={4} placeholder="Type your feedback" />
            </Form.Item>

            {formId === 2 &&
            <Card title="UAT Scenarios" className="mb-10">
              <Row gutter={[24, 24]}>
            {uatScenarios && uatScenarios.map((scenario, index) => (
              <React.Fragment key={index}>
              <Col span={12}>
                <Typography className='fs-16 mt-10'>{scenario.detail}</Typography>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={`uat_options[${index}]`}
                  label="UAT Respond"
                  rules={[{ required: true, message: 'Please select UAT Respond' }]}
                >
                  <Select size="large" placeholder="Select UAT Respond">
                    <Select.Option value="Testing Result OK">Testing Result OK</Select.Option>
                    <Select.Option value="Testing Not Successful">Testing Not Successful
                    </Select.Option>
                    <Select.Option value="Not Relevant">Not Relevant</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </React.Fragment>
            ))}
            </Row>
            </Card>
            }

            <Form.Item
              name="attachment"
              label="Attachment"
            >
              <Upload {...props} >
              <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>

            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              className="mt-24"
            >
              <Button
                type="primary"
                block
                disabled={loading}
                loading={loading}
                className="btn-blue mr-20"
                onClick={() => {
                  setIsDraft(false);
                  form.submit();
                }}
              >
                Save & Publish
              </Button>

              <Button
                loading={loading}
                disabled={loading}
                type="primary"
                onClick={() => {
                  setIsDraft(true);
                  form.submit();
                }}
              >
                Save Draft
              </Button>
            </div>
          </Form>
        </Card>
      </Spin>
    </DefaultLayout>
  );
}
