import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Row, Col, Space, Card, notification } from 'antd';
import { EnvironmentOutlined,EditOutlined, DeleteOutlined } from '@ant-design/icons';
import DefaultLayout from '../../components/layout/DefaultLayout';
import Header from '../../components/header';
import locationService from '../../services/location';

const Locations = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [editing, setEditing] = useState(null);

  const getLocations = async (page = 1) => {
    setLoading(true);

    await locationService.getLocations().then((response) => {
      setData(response);
      setCurrentPage(page);
      setTotalDataCount(response.total);
      setItemsPerPage(response.per_page);
      setLoading(false);
    });
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleDelete = (record) => {
    Modal.confirm({
      title: 'Confirm Deletion',
      content: `Are you sure you want to delete the location: ${record.name}?`,
      onOk: async () => {
        setLoading(true);
        try {
          const response = await locationService.deleteLocation(record.id);
          if (response.success) {
            notification.success({
              message: 'Location Deleted',
              description: response.message,
            });
            getLocations(currentPage);
          }
        } catch (error) {
          notification.error({
            message: 'Error',
            description: error.response.data.message,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditing(null);
    form.resetFields();
  };

  const handleEdit = record => {
    setEditing(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (editing) {
        const response = await locationService.updateLocation(editing.id, values);
        if (response.success) {
          setIsModalVisible(false);
          setEditing(null);
          form.resetFields();
          notification.success({
            message: 'Location Updated',
            description: response.message,
          });
          getLocations(currentPage);
        }

      } else {
        const response = await locationService.postLocation(values);
        if (response.success) {
          setIsModalVisible(false);
          setEditing(null);
          form.resetFields();
          notification.success({
            message: 'Location Added',
            description: response.message,
          });
          getLocations(currentPage);
        }

      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Location Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 80,
      render: (text, record) => (
        <Space>
        <div
     onClick={() => handleEdit(record)}
     style={{ cursor: 'pointer' }}
   >
     <EditOutlined /> 
   </div>
 
   <div
     onClick={() => handleDelete(record)}
     style={{ cursor: 'pointer' }}
   >
     <DeleteOutlined /> 
   </div>
   </Space>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header
            icon={<EnvironmentOutlined />}
            title="Locations"
            right={(
              <Button className='btn-blue' type="primary" onClick={showModal}>
                Add Location
              </Button>
            )}
          />
        </Col>
      </Row>

      <Card>
        <Table
          style={{ minHeight: '100vh' }}
          columns={columns}
          dataSource={data.map((item) => ({ ...item, key: item.id }))}
          loading={loading}
          pagination={{
                    total: totalDataCount,
                    current: currentPage,
                    pageSize: itemsPerPage,
                    onChange: (page, pageSize) => getLocations(page),
                  }}
        />
      </Card>

      <Modal
        title={editing ? 'Edit Location' : 'Add Location'}
        open={isModalVisible}
        onCancel={handleCancel}
        width={650}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} className='mt-20' layout="vertical">
          <Form.Item
            name="name"
            label="Location"
            rules={[
              { required: true, message: 'Please enter the location' },
            ]}
          >
            <Input size='large' placeholder="Type Location Name" />
          </Form.Item>
          <Form.Item>
            
            <Button type="primary" htmlType="submit" loading={loading}>
              {editing ? 'Save Changes' : 'Add Location'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </DefaultLayout>
  );
};

export default Locations;
