import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Tooltip, Typography } from 'antd';
import {
  LockFilled,
  UserOutlined,
  EnvironmentOutlined,
  DashboardOutlined,
  BranchesOutlined,
  AppstoreOutlined,
  DollarCircleOutlined,
  SolutionOutlined,
  SafetyCertificateOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  EditFilled,
  SettingOutlined,
  TeamOutlined,
  ApartmentOutlined,
  AppstoreAddOutlined,
  ContactsOutlined,
  TagOutlined,
  UsergroupAddOutlined,
  FormOutlined,
  CheckOutlined,
  ProfileOutlined,
  LogoutOutlined,
  ApiOutlined,
  UserSwitchOutlined,
  KeyOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import ProfilePictureComponent from './../profilePicture';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const DefaultLayout = ({ children }) => {
  const initialCollapsed =
    localStorage.getItem('menuCollapsed') === 'true' ? true : false;
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const [isVisible, setIsVisible] = useState(true);
  const { currentUser } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900 && !collapsed) {
        setCollapsed(true);
        setIsVisible(false);
      } else if (window.innerWidth >= 900 && collapsed) {
        setCollapsed(false);
        setIsVisible(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [collapsed]);

  useEffect(() => {
    localStorage.setItem('menuCollapsed', collapsed);
  }, [collapsed]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  useEffect(() => {
    if (location.state && location.state.prevUrl) {
      navigate(location.state.prevUrl, { replace: true });
    }
  }, [location, navigate]);

  const renderMenuItems = (items) => {
    return items.map((item) => (
      <Menu.Item key={item.id || `${item.key}-${item.route}`} icon={item.icon}>
        <Link to={item.route}>{item.label}</Link>
      </Menu.Item>
    ));
  };

  const userManagement = [
    {
      key: 'Role',
      icon: <ApartmentOutlined />,
      label: 'Roles',
      route: '/roles',
    },
    {
      key: 'User',
      icon: <UserOutlined />,
      label: 'User Data',
      route: '/users',
    },
    {
      key: 'ParallelApprover',
      icon: <UserSwitchOutlined />,
      label: 'Parallel Approvers',
      route: '/parallel-approvers',
    },
  ];

  const formMenuItems = [
    {
      key: 'SoftwareChangeRequestForm',
      icon: <FileTextOutlined />,
      label: 'Software Change Request Form',
      route: '/scrf',
    },
    {
      key: 'CapitalRequestForm',
      icon: <SolutionOutlined />,
      label: 'Capital Request Form',
      route: '/crf',
    },
    {
      key: 'QualityAssurance',
      icon: <SafetyCertificateOutlined />,
      label: 'Quality Assurance Form',
      route: '/quality-assurance',
    },
    {
      key: 'deployment',
      icon: <ExclamationCircleOutlined />,
      label: 'Deployment',
      route: '/deployment',
    },

    {
      key: 'MobileRequisition',
      icon: <ExclamationCircleOutlined />,
      label: 'Mobile Requisition Form',
      route: '/mobile-requisition',
    },
  ];

  const configPermissionMenuItems = [
    {
      key: 'CostCenter',
      icon: <DollarCircleOutlined />,
      label: 'Cost Center',
      route: '/cost-center',
    },

    {
      key: 'Equipment',
      icon: <ApiOutlined />,
      label: 'Equipments',
      route: '/equipments',
    },
  ];

  const serviceDeskMenuItems = [
    {
      key: 'ServiceDesk',
      icon: <BellOutlined />,
      label: 'Service Desk',
      route: '/service-desk',
    },
    {
      key: 'AssignTask',
      icon: <UserSwitchOutlined />,
      label: 'Assign Task',
      route: '/assign-task',
    },
  ];

  const menuItems = [
    {
      key: 'Department',
      icon: <TeamOutlined />,
      label: 'Departments',
      route: '/departments',
    },
    {
      key: 'Section',
      icon: <AppstoreAddOutlined />,
      label: 'Sections',
      route: '/sections',
    },
    {
      key: 'SoftwareCategory',
      icon: <TagOutlined />,
      label: 'Categories',
      route: '/categories',
    },
    {
      key: 'SoftwareSubcategory',
      icon: <AppstoreOutlined />,
      label: 'Subcategories',
      route: '/software-subcategories',
    },
    {
      key: 'Designation',
      icon: <ContactsOutlined />,
      label: 'Designations',
      route: '/designations',
    },
    {
      key: 'Location',
      icon: <EnvironmentOutlined />,
      label: 'Locations',
      route: '/locations',
    },
    {
      key: 'BusinessExpert',
      icon: <TeamOutlined />,
      label: 'Business Expert',
      route: '/business-expert',
    },
    {
      key: 'Subscribers',
      icon: <UsergroupAddOutlined />,
      label: 'Subscribers',
      route: '/subscribers',
    },
    {
      key: 'Approvers',
      icon: <CheckOutlined />,
      label: 'Approvers',
      route: '/approvers',
    },
  ];

  const allowedPermissions =
    currentUser?.['roles.permission']?.map((permission) => permission.name) ||
    [];
  //console.log(allowedPermissions)
  const filteredMenuItems = menuItems.filter((item) =>
    allowedPermissions.includes(`${item.key}-view`)
  );
  const filteredUserManagementItems = userManagement.filter((item) =>
    allowedPermissions.includes(`${item.key}-view`)
  );
  const filteredConfigurationMenuItems = configPermissionMenuItems.filter(
    (item) => allowedPermissions.includes(`${item.key}-view`)
  );
  const filteredServiceDeskMenuItems = serviceDeskMenuItems.filter((item) =>
    allowedPermissions.includes(`${item.key}-view`)
  );
  const filteredFormMenuItems = formMenuItems.filter((item) =>
    allowedPermissions.includes(`${item.key}-view`)
  );


  return (
    <Layout hasSider>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        {!collapsed && (
          <>
            <div className="user-profile">
              {currentUser && (
                <ProfilePictureComponent
                  user_id={currentUser?.user_id}
                  size={80}
                />
              )}
              <div className="mt-10">
                <Typography className="mb-10">{currentUser?.name}</Typography>
                <small>
                  <Tooltip title="Edit Profile">
                    <Link to="/profile" className="fs-16 mr-4">
                      <EditFilled />
                    </Link>
                  </Tooltip>
                </small>
                <small>
                  <Tooltip title="Change Password">
                    <Link to="/password" className="fs-16 mr-4">
                      <LockFilled />
                    </Link>
                  </Tooltip>
                </small>
                <small>
                  <Tooltip title="Logout">
                    <Link className="fs-16 mr-4" onClick={handleLogout}>
                      <LogoutOutlined />
                    </Link>
                  </Tooltip>
                </small>
              </div>
            </div>
          </>
        )}

        <Menu selectedKeys={[location.pathname]}> {/* Only one <Menu> */}
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>

          {/* User Management SubMenu */}
          {filteredUserManagementItems.length > 0 && (
            <SubMenu
              key="userManagement" // Unique key for SubMenu
              title="USER MANAGEMENT"
              icon={<BranchesOutlined />}
            >
              {renderMenuItems(filteredUserManagementItems)}
            </SubMenu>
          )}

          {/* Configurations SubMenu */}
          {filteredConfigurationMenuItems.length > 0 && (
            <SubMenu
              key="configurations" // Unique key for SubMenu
              title="CONFIGURATIONS"
              icon={<KeyOutlined />}
            >
              {renderMenuItems(filteredConfigurationMenuItems)}

              {/* Admin-specific Menu Items (with unique keys) */}
              {currentUser?.roles?.includes('admin') && (
                <>
                  <Menu.Item key="admin-forms" icon={<FormOutlined />}>
                    <Link to="/forms">Forms</Link>
                  </Menu.Item>

                  <Menu.Item key="admin-formPermissions" icon={<KeyOutlined />}>
                    <Link to="/form-permissions">Form Permissions</Link>
                  </Menu.Item>

                  <Menu.Item
                    key="assignPermissionLink"
                    icon={<UserSwitchOutlined />}
                  >
                    <Link to="/assign-permission">Assign Form Permissions</Link>
                  </Menu.Item>

                  <Menu.Item key="workflowLink" icon={<ProfileOutlined />}>
                    <Link to="/workflow">Workflow</Link>
                  </Menu.Item>

                  <Menu.Item key="team-group" icon={<SolutionOutlined />}>
                    <Link to="/team-group ">Create Team</Link>
                  </Menu.Item>

                  <Menu.Item key="team-member" icon={<SolutionOutlined />}>
                    <Link to="/team-member ">Assign Team Members</Link>
                  </Menu.Item>

                  <Menu.Item key="settings" icon={<SettingOutlined />}>
                    <Link to="/settings ">System Settings</Link>
                  </Menu.Item>
                </>
              )}
            </SubMenu>
          )}

          {filteredMenuItems.length > 0 && (
            <SubMenu
              key="SETUP FIELDS"
              title="SETUP FIELDS"
              icon={<BranchesOutlined />}
            >
              {renderMenuItems(filteredMenuItems)}
            </SubMenu>
          )}

          {filteredFormMenuItems.length > 0 && (
            <SubMenu key="FORMS" title="FORMS" icon={<FormOutlined />}>
              {renderMenuItems(filteredFormMenuItems)}
            </SubMenu>
          )}
        </Menu>

        <Menu>
        {filteredServiceDeskMenuItems.length > 0 && (
            <SubMenu
              key="SERVICE DESK"
              title="SERVICE DESK"
              icon={<BranchesOutlined />}
            >
              {renderMenuItems(filteredServiceDeskMenuItems)}
            </SubMenu>
        )}
        </Menu>
      </Sider>

      {/* <Button
        type="text"
        className={isVisible === true ? '' : 'menuButton'}
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: '16px',
          width: 30,
          height: 30,
          position: 'absolute',
        }}
      /> */}

      <Content
        style={{
          padding: '0 0px 0 0',
          minHeight: '100% !important',
        }}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default DefaultLayout;
