import api from '../libs/api';

export default {

  getDesignations: () => {
    return new Promise((resolve, reject) => {
        api()
            .get(`/designations`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data.data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  getAllDesignations: () => {
    return new Promise((resolve, reject) => {
        api()
            .get(`/get-all-designations`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data.data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  
  postDesignation: (data) => {
  return new Promise((resolve, reject) => {
      api()
          .post('designations', data)
          .then(({ status, data }) => {
              if (status === 200) resolve(data);
              throw new Error(`Failed with status: ${status}`);
          })
          .catch(error => reject(error));
  });
},


updateDesignation: (id, data) => {
  return new Promise((resolve, reject) => {
      api()
          .put(`/designations/${id}`, data)
          .then(({ status, data }) => {
              if (status === 200) resolve(data);
              throw new Error(`Failed with status: ${status}`);
          })
          .catch(error => reject(error));
  });
},

deleteDesignation: (id) => {
  return new Promise((resolve, reject) => {
      api()
          .delete(`/designations/${id}`)
          .then(({ status, data }) => {
              if (status === 200) resolve(data);
              throw new Error(`Failed with status: ${status}`);
          })
          .catch(error => reject(error));
  });
},


}