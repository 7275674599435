import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider, useSelector } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider, theme } from 'antd';

const Root = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [lightMode, setLightMode] = useState(true);

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode !== null) {
      setLightMode(savedMode === 'light');
    }
  }, []);

  const toggleMode = () => {
    const newMode = !lightMode;
    setLightMode(newMode);
    localStorage.setItem('themeMode', newMode ? 'light' : 'dark');
  };

  return (
    <ConfigProvider
    theme={{
      algorithm: lightMode ? [theme.defaultAlgorithm, theme.compactAlgorithm] : [theme.darkAlgorithm, theme.compactAlgorithm],
    }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
          {currentUser && <div onClick={toggleMode} style={{position: 'fixed', left: '1px', bottom: '10px', zIndex: 9999, cursor: 'pointer'}}>
            {lightMode ? '🌞' : '🌙'}
          </div>}
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

const rootElement = document.getElementById('root');
ReactDOM.createRoot(rootElement).render(
  <Provider store={store}>
    <Root />
  </Provider>
);
