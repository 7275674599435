import api from '../libs/api';

export default {

  getDepartments: () => {
    return new Promise((resolve, reject) => {
        api()
            .get(`/departments`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data.data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  getAllDepartments: () => {
    return new Promise((resolve, reject) => {
        api()
            .get(`/get-all-departments`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data.data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  postDepartment: (data) => {
  return new Promise((resolve, reject) => {
      api()
          .post('/departments', data)
          .then(({ status, data }) => {
              if (status === 200) resolve(data);
              throw new Error(`Failed with status: ${status}`);
          })
          .catch(error => reject(error));
  });
},

updateDepartment: (id, data) => {
  return new Promise((resolve, reject) => {
      api()
          .put(`/departments/${id}`, data)
          .then(({ status, data }) => {
              if (status === 200) resolve(data);
              throw new Error(`Failed with status: ${status}`);
          })
          .catch(error => reject(error));
  });
},

deleteDepartment: (id) => {
  return new Promise((resolve, reject) => {
      api()
          .delete(`/departments/${id}`)
          .then(({ status, data }) => {
              if (status === 200) resolve(data);
              throw new Error(`Failed with status: ${status}`);
          })
          .catch(error => reject(error));
  });
},


}