import React, { useState, useEffect } from 'react';
import { Select, Spin, notification } from 'antd';
import conditionService from '../../services/conditions';

const { Option } = Select;

const ConditionSelect = ({ selectedForm, value, onChange }) => {
  const [conditions, setConditions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getConditions = async () => {
      try {
        setLoading(true);
        const response = await conditionService.getConditions();
        setLoading(false);
        if (selectedForm === 2) {
          setConditions(response[0].form.conditions);
        } else if (selectedForm === 4) {
          setConditions(response[1].form.conditions);
        } else {
          setConditions(response[0].form.conditions);
        }
      } catch (error) {
        setLoading(false);
        notification.error({
          message: 'Error',
          description: error.response,
        });
      }
    };

    getConditions();
  }, [selectedForm]);

  return (
    <Spin spinning={loading}>
      <Select 
        showSearch 
        optionFilterProp="children" 
        size="large" 
        allowClear 
        value={value} 
        onChange={onChange}
      >
     {(selectedForm == 2 || selectedForm == 4) && <>
      {conditions.map((condition) => (
          <Option key={condition.id} value={condition.id}>{condition.name}</Option>
        ))}
     </>}
        
      </Select>
    </Spin>
  );
};

export default ConditionSelect;
